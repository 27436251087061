<template>
  <UModal v-model="confirm.open">
    <UCard>
      <div class="flex flex-col items-center sm:items-start sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <div
          class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full text-3xl"
          :class="{
            'bg-red-200 text-red-500': confirm?.item?.type === 'error',
            'bg-primary-200 text-primary-500': confirm?.item?.type === 'warning',
          }"
        >
          <UIcon
            v-if="confirm?.item?.type === 'error'"
            name="i-heroicons-exclamation-triangle-20-solid"
          />
          <UIcon
            v-if="confirm?.item?.type === 'warning'"
            name="i-heroicons-exclamation-circle-20-solid"
          />
        </div>
        <div class="space-y-2 text-center sm:text-left">
          <h3 class="text-lg leading-6 font-medium dark:text-white text-gray-900">
            {{ confirm?.item?.title }}
          </h3>
          <div
            class="text-base text-gray-500 dark:text-gray-400"
            v-html="confirm?.item?.description"
          />
        </div>
      </div>

      <template #footer>
        <div class="flex sm:justify-end space-x-3">
          <UButton
            v-for="(item, index) in confirm?.item?.buttons"
            :key="index"
            class="flex-1 justify-center sm:flex-none"
            :color="item.color"
            @click="handleClick(item.type)"
          >
            {{ item.label }}
          </UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script lang="ts" setup>
import type { Confirm } from '~/types/confirm'

const confirm = useState<Confirm>('confirm')
const $confirm = useConfirm()

const handleClick = (value: boolean) => {
  if (value) {
    confirm.value.item?.callback(value)
  }

  $confirm.actions.hide()
}
</script>
