<template>
  <UDropdown
    v-if="list.length"
    :items="[list]"
    :mode="['xs', 'sm'].includes($viewport.breakpoint.value) ? 'click' : 'hover'"
    :popper="{ placement: 'bottom-start' }"
    :ui="{
      padding: 'space-y-1',
    }"
  >
    <UButton
      color="gray"
      variant="ghost"
      :label="label"
      trailing-icon="i-heroicons-chevron-down-20-solid"
    />

    <template #item="{ item }">
      <UIcon
        :name="item.icon"
        class="text-gray-500 dark:text-gray-400"
      />
      <span class="truncate">
        {{ item.label }}
      </span>
      <UIcon
        v-if="item.current"
        name="i-heroicons-check-20-solid"
        class="flex-shrink-0 h-4 w-4 text-gray-500 dark:text-gray-400 ms-auto"
      />
    </template>
  </UDropdown>
</template>

<script lang="ts" setup>
import type { IField } from '~/types/collection'

interface Props {
  items: IField[]
  label?: string
}
const props = withDefaults(
  defineProps<Props>(),
  {
    label: ''
  }
)
const { items } = toRefs(props)

const $auth = useAuth()

const list = computed(() => {
  if (!items.value.length) {
    return []
  }

  return items.value[0].filter((_item: any) => $auth.can(_item.permission))
})
</script>
