<template>
  <div class="flex items-center space-x-1">
    <template v-for="(item, index) in items">
      <MenuDropdown
        v-if="item.type === 'dropdown' && $auth.canSome(item.permissions)"
        :key="index"
        :label="item.label"
        :items="[item.items[0].filter((item: IField) => $auth.can(item.permission))]"
      />
      <MenuButton
        v-if="item.type === 'button' && (item?.permission ? $auth.can(item.permission) : true)"
        :key="index"
        :item="item"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { IField } from '~/types/collection'

interface Props {
  items: IField[]
}
defineProps<Props>()

const $auth = useAuth()
</script>
