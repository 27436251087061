<template>
  <div class="bg-white dark:bg-gray-900 absolute left-0 right-0 bottom-0 top-[45px] z-50 overflow-x-hidden overflow-y-auto p-2">
    <UVerticalNavigation :links="filteredMenu">
      <template #default="{ link }">
        <div
          class="relative text-left w-full"
        >
          <div :class="{'mb-2': link?.items?.length}">
            {{ link.label }}
          </div>
          <UVerticalNavigation
            v-if="link?.items?.length"
            :links="link.items[0]"
          >
            <template #icon="{ link }">
              <UIcon
                v-if="link.current"
                name="i-heroicons-check-20-solid"
              />
            </template>
          </UVerticalNavigation>
        </div>
      </template>
      <template #icon="{ link }">
        <UIcon
          v-if="link.current"
          name="i-heroicons-check-20-solid"
        />
      </template>
    </UVerticalNavigation>
  </div>
</template>

<script lang="ts" setup>
import type { IField } from '~/types/collection'

interface Props {
  items: IField[]
}
const props = defineProps<Props>()
const { items } = toRefs(props)

const $auth = useAuth()

const filteredMenu = computed(() => items.value.filter((_item: IField) => {
  if (_item?.items?.length && $auth.canSome(_item.permissions)) {
    return _item.items[0].filter((__item: IField) => $auth.can(__item.permission))
  }

  return (_item?.permission ? $auth.can(_item.permission) : true)
}))
</script>
