<template>
  <UModal
    :model-value="open"
    :ui="['xs', 'sm'].includes($viewport.breakpoint.value) ? {
      padding: 'p-0',
      rounded: 'rounded-none',
      shadow: 'shadow-none'
    } : {}"
    @close="$emit('close')"
  >
    <UCard
      :ui="['xs', 'sm'].includes($viewport.breakpoint.value) ? {
        rounded: 'rounded-none',
        shadow: 'shadow-none'
      } : {}"
    >
      <template #header>
        <div class="text-xl font-medium">
          Быстрые клавиши
        </div>
      </template>

      <ul class="space-y-3">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="flex items-center justify-between space-x-3"
        >
          <div>
            {{ item.label }}
          </div>
          <div class="flex space-x-1 uppercase">
            <UKbd
              v-for="(shortcut, shortcutIndex) in item.shortcuts"
              :key="shortcutIndex"
              size="md"
            >
              {{ shortcut }}
            </UKbd>
          </div>
        </li>
      </ul>
    </UCard>
  </UModal>
</template>

<script lang="ts" setup>
interface Emits {
  (event: 'close'): void
}
interface Props {
  open: boolean
}
defineEmits<Emits>()
defineProps<Props>()

const items = ref([
  {
    label: 'Предыдущий месяц / Предыдущая страница',
    shortcuts: ['A']
  },
  {
    label: 'Следующий месяц / Следующая страница',
    shortcuts: ['D']
  }
])
</script>
